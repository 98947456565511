const cheerio = require("cheerio");

export async function parseKrogerReceipt(e) {

    const items = [];

    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => { 
      const text = (e.target.result)
      const $ = cheerio.load(text);

      const recieptPrintArea = $("#receipt-print-area");
      const firstInnerDiv = recieptPrintArea;
      const itemsContainer = firstInnerDiv.children().eq(0).children().eq(6).children().eq(0).children();
      
      itemsContainer.children().each((index, element) => {
        if (index !== 0) 
        { 
            const child = $(element);
            let itemName = child.children().first().children().first().text();
            let itemPrice = child.children().first().children().eq(1).text();

            if (itemName.trim() !== "" && itemPrice.trim() !== "")
            items.push({
                id: index - 1,
                itemName,
                itemPrice: parseFloat(itemPrice.split("$")[1]),
                C: 0,
                L: 0
            });
        }
        
      });

      // Find and add the tax automatically as item
      items.push({
        id: items.length + 1,
        itemName: "Tax",
        itemPrice: parseFloat($('span:contains("Sales Tax")').parent().text().split("$")[1]),
        C: 0,
        L: 0
      })
      
    };
    reader.readAsText(e.target.files[0])

    return items;
}