import {React, useEffect, useState, useRef} from "react";
import Item from "../Item/Item";
import "./ItemList.css";
import { Button, Checkbox, Label, Modal, TextInput } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export default function ItemList(props){

    const [totals, setTotals] = useState({C: 0, L: 0});

    const [openModal, setOpenModal] = useState(false);
    const itemNameRef = useRef(null);
    const itemPriceRef = useRef(null);

    const onAddItem = () => {

        // Item name already taken
        if (itemNameRef.current != null && 
            props.items.find((el) => el.itemName === itemNameRef.current.value)){
            window.confirm(`Item name "${itemNameRef.current.value}" already taken!`);
            return;
        }

        let i = [...props.items];
        i.push({
            id: props.items.length + 1,
            itemName: itemNameRef.current.value, 
            itemPrice: itemPriceRef.current.value,
            C: 0,
            L: 0
        });
        props.setItems(i);
        setOpenModal(false);
    }

    useEffect(() => {
        if (itemNameRef.current != null && itemPriceRef.current != null){
            itemNameRef.current.value = "";
            itemPriceRef.current.value = "";
        }

    }, [openModal]);

    useEffect(() => {
        
    }, [props.items]);



    const onItemDelete = (itemObj) => {
        if (itemObj == null) { return; }
        props.setItems(prev => prev.filter((el) => el.id !== itemObj.id)); // filter by id
    }

    const setItemSelectionValues = (itemId, selection) => {
        let item = props.items.find((el) => el.id === itemId);
        if (item != null){
            switch (selection){
                case "C":
                    item.C = item.itemPrice;
                    item.L = 0;
                    break;
                case "L":
                    item.L = item.itemPrice;
                    item.C = 0;
                    break;
                case "BOTH":
                    item.C = parseFloat(item.itemPrice) / 2;
                    item.L = parseFloat(item.itemPrice) / 2;
                    break;
            }
        }

        let allItems = [...props.items];
        for (let i = 0; i < allItems.length; i++){
            if (allItems[i].id === item.id){
                allItems[i] = item;
                break;
            }
        }

        let c_total = 0;
        let l_total = 0;
        // Calculate totals
        for (let i = 0; i < allItems.length; i++){
            c_total += Math.abs(allItems[i].C);
            l_total += Math.abs(allItems[i].L);
        }

        props.setItems(allItems);
        setTotals({C: c_total, L: l_total});
    }

    const numberFormat = (value) =>
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(value);

    return (
        <>
            <div className="list-outer w-3/4 h-full">
                {props.items.map((item) => {return <Item item={item} key={item.id} onItemDelete={onItemDelete} setItemSelectionValues={setItemSelectionValues}/>})}

                <button onClick={() => setOpenModal(true)} className="font-bold item-card w-full justify-center items-center">
                    <p className="text text-3xl self-center">+ Add Item</p>
                </button>

                <hr className="h-px my-8 bg-gray-200 border-1 dark:bg-gray-700"></hr>
                <p className="text text-3xl text-left font-bold w-full mb-5">Price Split (with tax)</p>
                <div className=" flex w-full text-left justify-left align-center">
                    <h1 className="text text-3xl mx-10">C: {numberFormat(totals.C)}</h1>
                    <h1 className="text text-3xl mx-10">L: {numberFormat(totals.L)}</h1>
                </div>
                <hr className="h-px my-8 bg-gray-200 border-1 dark:bg-gray-700"></hr>
                <div className="total-container">
                    <p className="text text-3xl text-left font-bold w-full mt-8">Total (with tax)</p>
                    <h1 className="text text-3xl mx-10 mt-5">{numberFormat(totals.C + totals.L)}</h1>
                </div>
            </div>

            <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
                <Modal.Header>
                    <h1 className="text text-xl font-bold m-3">Add Item</h1>
                </Modal.Header>
                <Modal.Body>
                <div className="text-center justify-center w-full items-center align-center">
                    <div className="text-left">
                        <Label htmlFor="item-name-input" value="Item Name" className="text text-lg font-bold"/>
                        <TextInput type="text" id="item-name-input" className="mt-2" ref={itemNameRef}></TextInput>
                        <br/>
                        <Label htmlFor="item-price-input" value="Item Price" className="text text-lg font-bold"/>
                        <TextInput type="number" id="item-price-input" className="mt-2" ref={itemPriceRef}></TextInput>
                        <br/>
                    </div>
    
                    <Button onClick={onAddItem} className="self-center w-full">Add</Button>
                </div>
                </Modal.Body>
            </Modal>
        </>
    );
}