const cheerio = require("cheerio");

export async function parseWalmartReceipt(e) {

    const items = [];

    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => { 
      const text = (e.target.result)
      const $ = cheerio.load(text);
      const accordion = $('ul[class="results-list list-view"]');
      const childElements = accordion.find('div[class="text-left Grid"]');
      
      for (let i = 0; i < childElements.get(0).childNodes.length; i++) {
        let element = childElements.get(0).childNodes[i];
        

        let itemName = $(element).find('div[class="item-details"]').text();

        // remove price from end of string (weird parse issue)
        if (itemName.includes("$")){
          itemName = itemName.replace(itemName.substring(itemName.indexOf("$")), "");
        }

        let itemPrice = $(element).find('span[class="item-price"]').text();

        if (itemName.trim() !== "" && itemPrice.trim() !== ""){
            items.push({
                id: i - 1,
                itemName,
                itemPrice: parseFloat(itemPrice.split("$")[1]),
                C: 0,
                L: 0
            })
        }
      };

      // Find and add tax automatically as item
      items.push({
        id: items.length + 1,
        itemName: "Tax",
        itemPrice: parseFloat($('div[class="receipt-summary-v2"]').find('td[class="text-red"]').text().split("$")[1]),
        C: 0,
        L: 0
      })
    };
    reader.readAsText(e.target.files[0])

    return items;
}